import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { Toast } from '@meetup/swarm-components';
import Example from '../../components/examples/toast';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Toast`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<Toast status="warning" id="test">
  <p>Test</p>
</Toast>
`}</code></pre>
    <h1>{`Example`}</h1>
    <Example mdxType="Example" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      